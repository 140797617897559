<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <v-container>
          <br />
          <v-container class="py-0" v-if="ErrorMessageTxtFlag == 1">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">{{ ErrorMessageTxt }}</h3>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0">
            <v-form ref="form" v-model="valid" lazy-validation id="createForm">
              <v-row wrap>
                <v-col cols="12" md="3">
                  <h4 class="text-primary">
                    <span class="text-danger">*</span> Year
                  </h4>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="JciYearCodeOptions"
                    :rules="JciYearCodeRules"
                    v-model="JciYearCode"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <h4 class="text-primary">
                    <span class="text-danger">*</span> Zone
                  </h4>
                  <v-autocomplete
                    @change="getJcomTableOptions"
                    v-model="ZoneCode"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    :reduce="(option) => option.value"
                    required
                    outlined
                    clearable
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <h4 class="text-primary">
                    <span class="text-danger">*</span> JCOM Tables
                  </h4>
                  <v-autocomplete
                    v-model="JcomTable"
                    :items="JcomTableOptions"
                    :rules="JcomTableRules"
                    :reduce="(option) => option.value"
                    required
                    outlined
                    clearable
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                    @click="searchForm"
                  >
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-container class="py-0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <div
                  class="d-flex justify-content-center mb-3"
                  v-if="ProgessStart == 1"
                >
                  <b-spinner
                    variant="primary"
                    type="grow"
                    label="We are fetching details, please wait..."
                  ></b-spinner>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <br />
          <h2 align="center">STATISTICS</h2>
          <hr />
          <br />
          <v-row>
            <div
              class="col-xl-3 col-md-4 mb-4"
              v-for="(row, index) in records"
              :key="index"
            >
              <v-col align="center">
                <v-card large class="custom-card">
                  <v-card-text>
                    <v-row wrap>
                      <v-row>
                        <v-col
                          align="center"
                          cols="12"
                          lg="12"
                          md="12"
                          sm="12"
                          class="pt-0 pb-0"
                        >
                        </v-col>
                        <v-col class="d-inline pt-0 pb-0" cols="auto">
                          <h6 class="pt-0 pb-0" style="color: #14110f">
                            {{ row.MembershipId }}
                          </h6>
                        </v-col>

                        <v-col class="pt-0 pb-0">
                          <h4
                            class="pt-0 pb-0 mb-0"
                            style="color: #14110f; text-align: left"
                          >
                            {{ row.MemberName }}
                          </h4>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                large
                                :color="row.IconColor"
                                v-bind="attrs"
                                v-on="on"
                                class="pt-0 pb-0 mb-0"
                              >
                                {{ row.MemberStatus }}
                              </v-icon>
                            </template>
                            <span>{{row.IconStatus}}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row class="align-center">
                        <v-col class="d-flex align-center pa-0" cols="auto">
                          <img
                            class="rounded-circle"
                            :src="row.ProfilePhotoPath"
                            alt="Profile Photo"
                            width="70px"
                            height="70px"
                          />
                        </v-col>

                        <v-col class="pa-0" cols="auto">
                          <h6
                            class="mb-0"
                            style="color: #14110f; margin-left: 10px"
                          >
                            {{ row.CompanyName }}
                          </h6>
                          <p
                            class="mb-0"
                            id="Category"
                            style="margin-left: 10px; text-align: left"
                          >
                            <b>{{ row.BusinessName }}</b>
                          </p>
                        </v-col>
                      </v-row>

                      <v-row class="justify-content-center">
                        <v-col
                          cols="auto"
                          class="d-flex mb-3 align-items-center p-0"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                icon
                                size="18px"
                                color="#ff579f"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fa-user-tie
                              </v-icon>
                            </template>
                            <span> Connect Given </span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="m-0 custom-para p-2 flex-grow-1"
                                v-bind="attrs"
                                v-on="on"
                              >
                                : {{ row.ConnectGiven }}
                              </p>
                            </template>
                            <span> Connect Given </span>
                          </v-tooltip>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="d-flex mb-3 align-items-center p-0"
                        >
                          <br />
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                icon
                                size="18px"
                                color="#7209b7"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fa-handshake
                              </v-icon>
                            </template>
                            <span> Connect Recived </span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="m-0 custom-para p-2 flex-grow-1"
                                v-bind="attrs"
                                v-on="on"
                              >
                                : {{ row.ConnectRecived }}
                              </p>
                            </template>
                            <span> Connect Received </span>
                          </v-tooltip>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="d-flex mb-3 align-items-center p-0"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                icon
                                size="18px"
                                color="#ff5e5b"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fa-chart-line
                              </v-icon>
                            </template>
                            <span> Gnote Given </span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="m-0 custom-para p-2 flex-grow-1"
                                v-bind="attrs"
                                v-on="on"
                              >
                                : {{ row.GnoteGiven }}
                              </p>
                            </template>
                            <span> Gnote Given</span>
                          </v-tooltip>
                        </v-col>
                        <v-col
                          cols="auto"
                          class="d-flex mb-3 align-items-center p-0"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                icon
                                size="18px"
                                color="#49111c"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fa-briefcase
                              </v-icon>
                            </template>
                            <span> Gnote Recived </span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <p
                                class="mr-2 custom-para p-2 flex-grow-1"
                                v-bind="attrs"
                                v-on="on"
                              >
                                : {{ row.GnoteRecived }}
                              </p>
                            </template>
                            <span> Gnote Received </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row class="mt-0">
                        <!-- <v-tooltip bottom v-if="item.InitiatedBlockFlag">
                      <template v-slot:activator="{ on, attrs }"> -->
                        <v-btn
                          color="#a52a2a"
                          elevation="10"
                          type="submit"
                          class="mr-3 btn font-size-h6 px-2 py-1 my-3 mt-0 white--text"
                          shaped
                          tile
                          x-small
                          @click="blockMembersStatus(row)"
                        >
                          Block
                        </v-btn>
                        <!-- </template>
                        </v-tooltip> -->
                         <!-- <v-tooltip bottom v-if="item.BlockFlag">
                      <template v-slot:activator="{ on, attrs }"> -->
                        <v-btn
                          color="#2a8d08"
                          elevation="10"
                          type="submit"
                          class="mr-3 btn font-size-h6 px-2 py-1 my-3 mt-0 white--text w-1"
                          shaped
                          tile
                          x-small
                          @click="approvedMemberStatus(row)"
                        >
                          Approved
                        </v-btn>
                        <!-- </template>
                         </v-tooltip> -->
                        <v-btn
                          color="#8950FC"
                          elevation="10"
                          type="submit"
                          class="mr-3 btn font-size-h6 px-2 py-1 my-3 mt-0 white--text"
                          shaped
                          tile
                          x-small
                          @click="membersReport(row)"
                        >
                          Report
                        </v-btn>
                      </v-row>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </div>
          </v-row>
          <br />
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editRecordPrompt" persistent max-width="70%">
      <jcom-members-status-update
        :editRecordPrompt="editRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        :recordData="recordData"
        v-if="editRecordPrompt"
      ></jcom-members-status-update>
    </v-dialog>
    <v-dialog v-model="blockMemberRecordPrompt" persistent max-width="70%">
      <initiated-block-member
        :blockMemberRecordPrompt="blockMemberRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        :recordData="recordData"
        v-if="blockMemberRecordPrompt"
      ></initiated-block-member>
    </v-dialog>
    <v-dialog v-model="approvedMemberRecordPrompt" persistent max-width="70%">
      <approved-members-status
        :approvedMemberRecordPrompt="approvedMemberRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        :recordData="recordData"
        v-if="approvedMemberRecordPrompt"
      ></approved-members-status>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import JcomMembersStatusUpdate from "@/view/pages/erp/jcom/jcom-table-members/JcomMembersStatusUpdate.vue";
import InitiatedBlockMember from "@/view/pages/erp/jcom/jcom-table-members/InitiatedBlockMember.vue";
import ApprovedMembersStatus from "@/view/pages/erp/jcom/jcom-table-members/ApprovedMembersStatus.vue";

export default {
  mixins: [common],
  components: {
    JcomMembersStatusUpdate,
    InitiatedBlockMember,
    ApprovedMembersStatus,
  },
  data() {
    return {
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      ZipDownloadFlag: false,
      ZipFileName: "",

      SelectFlag: true,
      DeleteFlag: false,

      alert: {},

      valid: true,
      CardContent: [],

      JciYearCodeFlag: false,
      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],

      MemberTypeRules: [],
      MemberType: 1,
      MemberTypeOptions: [
        { value: 1, text: "Member" },
        { value: 3, text: "SMA Member" },
      ],

      IdCardTypeRules: [],
      IdCardType: 0,
      IdCardTypeOptions: [
        { value: 0, text: "Not issued" },
        { value: 1, text: "Issued" },
      ],

      DownloadMemberPhotosRules: [],
      DownloadMemberPhotos: 0,
      DownloadMemberPhotosOptions: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],

      JcomTableRules: [(v) => !!v || "Table is required"],
      JcomTable: "",
      JcomTableOptions: [],

      MemberNameRules: [(v) => !!v || "Name is required"],
      MemberEmailRules: [],
      MemberMobileNoRules: [],
      MemberAddressRules: [],
      MemberCityRules: [],

      totalActiveAwards: 0,
      totalPendingAwards: 0,

      lastDate: "15-JAN-2021 11:59 PM",

      RoleId: "",

      ResultFlag: 0,
      BtnShowFlag: 0,

      NewMembers: [],

      bgColor: "#778899",
      position: "top-right",
      fabActions: [
        /*
        {
          name: 'deleteBtn',
          icon: 'delete',
          color: 'red',
        },
        */
        {
          name: "previewBtn",
          icon: "search",
          color: "orange",
        },
      ],

      singleSelect: true,
      selected1: [],
      selected2: [],
      search: "",
      search2: "",
      loading: true,

      addRecordPrompt: false,
      editRecordPrompt: false,
      blockMemberRecordPrompt: false,
      approvedMemberRecordPrompt: false,
      previewRecordPrompt: false,

      recordToEdit: "",
      recordData: "",

      PaymentPaidDate: "",
      menu2: false,

      dialog: false,
      dialogDelete: false,
      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      TotalMembers: 0,

      editedIndex: -1,
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;
        this.JciYearCode = CurrentJciYearId;

        this.getJciYearCodeOptions();
        this.getZoneCodeOptions();
      }
    },
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 = rows.length;
      var flag = n1 == 0 ? 0 : 1;
      console.log("n1=" + n1 + ", flag=" + flag);
      this.ResultFlag = flag;
      if (n1 > 0) {
        var filter1 = {
          ActiveStatusTxt: "Active",
        };
        console.log("filter1=" + JSON.stringify(filter1));
        var records1 = rows;
        records1 = records1.filter(function (item) {
          for (var key in filter1) {
            if (item[key] === undefined || item[key] != filter1[key])
              return false;
          }
          return true;
        });
        var n2 = records1.length;
        console.log("n2=" + n2 + ", records1=" + JSON.stringify(records1));

        var filter2 = {
          ActiveStatusTxt: "Pending",
        };
        console.log("filter2=" + JSON.stringify(filter2));
        var records2 = rows;
        records2 = records2.filter(function (item) {
          for (var key in filter2) {
            if (item[key] === undefined || item[key] != filter2[key])
              return false;
          }
          return true;
        });
        var n3 = records2.length;
        console.log("n3=" + n3 + ", records2=" + JSON.stringify(records2));

        this.totalActiveAwards = n2;
        this.totalPendingAwards = n3;
      }
    },
    IdCardType: function () {
      console.log("watch IdCardType");
      var IdCardType = this.IdCardType;
      console.log({ IdCardType });
      this.tableData1 = [];
      this.selected1 = [];
      this.DownloadMemberPhotos = 0;
    },
    JcomTableOptions: function () {
      console.log("watch JcomTableOptions");
      this.JcomTableOptionsLoading = false;
    },
    JciYearCode: function () {
      console.log("Watch JciYearCode called");
      this.resetFrom();
    },
    ZoneCode: function () {
      console.log("Watch ZoneCode called");
      this.resetFrom();
    },
    JcomTable: function () {
      console.log("Watch JcomTable called");
      this.resetFrom();
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    rowSelected(tr) {
      console.log("rowSelected called");
      console.log("tr=" + JSON.stringify(tr));
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    resetFrom() {
      console.log("resetFrom called");
      this.tableColumns1 = {};
      this.tableOptions1 = {};
      this.tableData1 = {};

      this.DownloadFlag = false;
      this.ExcelFileName = "";
      this.ZipDownloadFlag = false;
      this.ZipFileName = "";
      this.tableData1 = [];
      this.selected1 = [];
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
      this.totalActiveAwards = 0;
      this.totalPendingAwards = 0;
    },

    hideEditRecordPrompt(flag) {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.blockMemberRecordPrompt = false;
      this.approvedMemberRecordPrompt = false;
      console.log("flag = " + flag);
      if (flag) {
        this.searchForm();
      }
    },
    membersReport(tr) {
      console.log("membersReport called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.recordData = tr;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    blockMembersStatus(tr) {
      console.log("blockMembersReport called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.recordData = tr;
        this.blockMemberRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    approvedMemberStatus(tr) {
      console.log("approvedMemberStatus called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.recordData = tr;
        this.approvedMemberRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "jcom_members",
        Action: "list_2",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: 3,
        ZoneCode: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getFormFieldOptions() {
      console.log("getFormFieldOptions called");
      // this.getContributionTypeIdOptions();
      this.getZoneCodeOptions();
    },
    getContributionTypeIdOptions() {
      console.log("getContributionTypeIdOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ContributionTypeId";
        var selectbox1_destination = "ContributionTypeIdOptions";
        var selectbox1_url = "api/year-wise-contribution/type-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Jci Year should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.CurrentYearId;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/jcom/table/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getJcomTableOptions() {
      console.log("getJcomTableOptions called");
      var ZoneCode = this.ZoneCode;
      console.log("ZoneCode=" + ZoneCode);
      if (ZoneCode != "") {
        var selectbox1_source = "JcomTable";
        var selectbox1_destination = "JcomTableOptions";
        var selectbox1_url = "api/jcom/table/options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          Zone: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (TableType == "") {
          message += "Table Type should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    searchForm() {
      console.log("searchForm called");

      this.resetMessageTxt();

      var ZoneCode = this.ZoneCode;
      var JcomTable = this.JcomTable;
      console.log("ZoneCode=" + ZoneCode + ", JcomTable=" + JcomTable);

      var result = this.$refs.form.validate();
      console.log("result=" + result);

      if (result) {
        this.DownloadFlag = false;
        this.ExcelFileName = "";
        this.ZipDownloadFlag = false;
        this.ZipFileName = "";
        this.tableData1 = [];
        this.selected1 = [];
        this.CardContent = [];

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/membership/table-members";
        var upload = {
          UserInterface: 4,
          Year: this.JciYearCode,
          Zone: ZoneCode,
          Table: JcomTable,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = [];
        var table_content = [];
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            table_content = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            var options = table_content.TableOptions;
            // records = table_content.TableData;

            if (flag == 1) {
              thisIns.tableColumns1 = table_content.TableHeader;
              thisIns.records = table_content.TableData;
              thisIns.CardContent = table_content.CardContent;
              thisIns.tableOptions1 = options;
              thisIns.tableData1 = records;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
              thisIns.ZipDownloadFlag = options.ZipDownloadFlag;
              thisIns.ZipFileName = options.ZipFileName;

              thisIns.toast("success", output);
            } else {
              console.log("error=" + output);
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
          });
      } else {
        var message = "Kindy fill required fields and then submit";
        this.toast("error", message);
      }
    },
    updateConfirmAlert() {
      this.resetMessageTxt();

      var selected = this.selected1;
      var n1 = selected.length;
      console.log("n1=" + n1 + ", selected=" + JSON.stringify(selected));

      var result = this.$refs.form.validate();
      console.log("result=" + result);

      if (n1 > 0 && result) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            var upload = {
              Year: this.JciYearCode,
              Members: selected,
            };
            this.updateRecords(upload);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (!result) {
          message += "Kindly fill the required fields. ";
        }
        if (n1 == 0) {
          message += "Atleast select one member to update. ";
        }
        this.toast("error", message);
      }
    },
    updateRecords(upload) {
      console.log("updateRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/reports/issued-cards-update";
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // thisIns.pageLoadingOn()
      thisIns.ProgessStart = 1;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.ProgessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.tableData1 = [];
            thisIns.searchForm();
            thisIns.sweetAlert("success", output, true);
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.selectedData = tr;
        this.confirmAlert();
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      console.log("confirmAlert called");
      var tr = this.selectedData;
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var htmlTxt = "";
        htmlTxt += "<br/>Member <b> " + tr.MemberNameTxt + "</b>";
        htmlTxt += "<br/>Table Name <b> " + tr.TableName + "</b>";
        htmlTxt += "<br/>Table Type <b> " + tr.TableTypeName + "</b>";
        htmlTxt += "<br/>Zone <b> " + tr.TableZoneName + "</b>";
        htmlTxt += "<br/><br/>Do you want to delete the data?";

        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to delete the data?",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Delete`,
          confirmButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#3085d6",
        }).then((result) => {
          if (result.isConfirmed) {
            this.deleteRecord(tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly select one record to delete";
        this.sweetAlert("error", message, false);
      }
    },
    updateBusinessDetails() {
      console.log("updateBusinessDetails called");
      var id = this.RoleId;
      // var id = 1;
      console.log({ id });

      if (id == 2) {
        this.OverlayLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jcom/membership/update-business";
        var upload = {
          UserInterface: 1,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              if (thisIns.tableData1.length > 0) {
                thisIns.searchForm();
              }
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        var message = "Permission Denied.";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    var RoleId = this.$session.get("RoleId");
    RoleId = RoleId == (null || undefined) ? "" : RoleId;
    console.log({ RoleId });
    this.RoleId = RoleId;
    // this.RoleId = 1;

    this.refreshPageData();
  },
};
</script>

<style lang="scss">
.custom-card {
  max-width: 1200px;
  height: auto;
  padding: 20px;
  background: linear-gradient(400deg, #c9def4 20%, #f5ccd4, #b8a4c9);
}
.custom-para {
  font-size: 15px;
  margin-top: 15px;
  color: #14110f;
}
</style>
